import React, { Component } from 'react';
import classes from './Screen.module.scss';
import InitialScreen from './InitialScreen/InitialScreen';
import QrScreen from './QrScreen/QrScreen';
import QuestionScreen from './QuestionScreen/QuestionScreen';
import TutorialScreen from './TutorialScreen/TutorialScreen';
import AlertScreen from './AlertScreen/AlertScreen';
import WinnerScreen from './WinnerScreen/WinnerScreen';


class Screen extends Component {
    state = {
        userId: null,
        progress: 'initialScreen',
        gameName: null,
        gameType: null,
        logo: null,
        qrCode: null,
        gameUrl: null,
        usersCount: 0,
        questionsCount: null,
        timerTime: null,
        currentQuestion: 0,
        isOptionsVisible: false,
        questionText: null,
        options: null,
        isCountdownStarted: false,
        correctAnswer: null,
        votes: null,
        isResultsShown: false,
        totalUsersCount: null,
        winnerName: null
    }

    socket = new WebSocket("ws://europc.pynex.co:2346");

    componentDidMount() {

        this.socket.onopen = () => {
            console.log('opened')
            const userId = sessionStorage.getItem('userId');
            if(userId) {
                console.log('user id sent')
                this.socket.send(JSON.stringify({
                    user_id: userId,
                    type: 'reconnect'
                }));
                this.setState({
                    userId: userId
                })
            }
        };
        
        this.socket.onclose = (event) => {
            if (event.wasClean) {
            console.log('Соединение закрыто чисто');
            } else {
            console.log('Обрыв соединения'); // например, "убит" процесс сервера
            }
            console.log('Код: ' + event.code + ' причина: ' + event.reason);
        };
        
        this.socket.onmessage = (event) => {
            this.onResponse(event.data) 
        };
        
        this.socket.onerror = (error) => {
            console.log("error: " + error.message);
        };
    }

    onResponse(data) {
        let response = JSON.parse(data)
        console.log(response)
        switch(response.type){
            case 'players':
                this.setState({
                    usersCount: response.count
                })
                break;
            case 'userId':
                if(this.state.userId){
                    this.socket.send(JSON.stringify({
                        input: 'screen',
                        type: 'game_info',
                        game_id: 1,
                        user_id: this.state.userId
                    }));
                } else {
                    this.socket.send(JSON.stringify({
                        input: 'screen',
                        type: 'game_info',
                        game_id: 1,
                        user_id: response.id
                    }));
                    sessionStorage.setItem('userId', response.id)
                    this.setState({
                        userId : response.id
                    })
                }
                break;
            case 'game_info':
                this.setState({
                    gameName: response.game_name,
                    gameType: response.game_type,
                    logo: response.logo,
                    questionsCount: response.questions_count,
                    timerTime: response.timer_sec
                })
                break;
            case 'show_qr':
                this.setState({
                    qrCode: response.qr_code,
                    gameUrl: response.url,
                    progress: 'qrCode'
                })    
                break;
            case 'to_tutorial':
                this.setState({
                    progress: 'tutorial'
                })
                break;
            case 'question':
                this.setState({
                    currentQuestion: response.num,
                    questionText: response.question,
                    isOptionsVisible: false,
                    isResultsShown: false,
                    progress: 'question',
                    correctAnswer: null
                })    
                break;
            case 'show_options':
                this.setState({
                    isOptionsVisible: true,
                    options: response.options,
                    isCountdownStarted: false
                })
                break;
            case 'start_timer': 
                this.setState({
                    isCountdownStarted: true
                })
                break;
            case 'true_answer':
                this.setState({
                    correctAnswer: +response.true_option,
                    votes: response.votes,
                    usersCount: response.players,
                    isResultsShown: true
                })
                break;
            case 'show_alert':
                this.setState({
                    progress: 'alert'
                })
                break;
            case 'winner':
                this.setState({
                    progress: 'winner',
                    totalUsersCount: response.totalPlayers,
                    winnerName: response.winnersName
                })
                break;
            default:
                console.log('unknown message')
                break;
        }
    }

    render() {

        let content = null;

        switch(this.state.progress) {
            case 'initialScreen':
                content = <InitialScreen name={ this.state.gameName } />
                break;
            case 'qrCode':
                content = <QrScreen
                    usersCount={ this.state.usersCount } 
                    qrCode={ this.state.qrCode }
                    gameUrl={ this.state.gameUrl }
                />
                break;
            case 'question':
                content = <QuestionScreen
                    questionsCount={ this.state.questionsCount }
                    currentQuestion={ this.state.currentQuestion }
                    playersCount={ this.state.usersCount }
                    timerTime={ this.state.timerTime }
                    isOptionsVisible={ this.state.isOptionsVisible }
                    questionText={ this.state.questionText }
                    options={ this.state.options }
                    isCountdownStarted={ this.state.isCountdownStarted }
                    correctAnswer={ this.state.correctAnswer }
                    isResultsShown={ this.state.isResultsShown }
                    votes={ this.state.votes }
                    
                />
                break;
            case 'tutorial':
                content = <TutorialScreen
                    time={ this.state.timerTime }
                    usersCount={ this.state.usersCount } 
                    qrCode={ this.state.qrCode }
                />
                break;
            case 'alert':
                content = <AlertScreen name={ this.state.gameName } />
                break;
            case 'winner':
                content = <WinnerScreen
                    name={ this.state.gameName }
                    totalUsersCount={ this.state.totalUsersCount }
                    winnerName={ this.state.winnerName }
                />
                break;
        }

        return(
            <div className={ classes.screen }>
                { content }
            </div>
        )
    }
}

export default Screen;