import React from 'react';
import classes from './LooserScreen.module.scss';
import Dots from '../Question/Dots/Dots';
import looserIcon from '../../../assets/img/looser.svg';

const LooserScreen = (props) => (
    <div className={ classes.looserScreen }>
        <div className={ classes.eventName }>{ props.gameName }</div>
        <div className={ classes.greetings }>
            { props.name }, увы, ты ошибся
        </div>
        <div className={ classes.results }>
            <span>#{ props.rankingPlace ? props.rankingPlace : 2 }</span>/{ props.totalPlayers }
        </div>
        <img src={ looserIcon } />
        <Dots
            currentstatus={ 'wrong' }
            questionsCount={ props.questionsTotalNumber }
            currentQuestion={ props.questionNumber }
        />
    </div>
)

export default LooserScreen;