import React from 'react';
import classes from './SignUp.module.scss';
import BottomButton from '../../BottomButton/BottomButton';
import cn from 'classnames';

const SignUp = (props) => {
    return(
        <div className={ classes.wrapper }>
        <div className={ classes.eventName }>{ props.gameName }</div>
            <div className={ classes.form }>
                <div className={ classes.title }>Представься</div>
                <input 
                    type="text" 
                    value={ props.name } 
                    onChange={ (event) => props.changed(event) } 
                    placeholder="Имя*"
                    className={ cn({
                        [classes.error]: props.error
                    }) }
                />
                <div className={ classes.subtitle }>Это нужно нам для того, чтобы было проще тебя определить!</div>
            </div>
            <BottomButton clicked={ props.readyClicked }>Погнали!</BottomButton>
        </div>
    )
}

export default SignUp;

