import React from 'react';
import classes from './Controls.module.scss';
import cn from 'classnames';

const Controls = (props) => {

    let dots = []

    for (let count = 1; count <= props.slidesNum; count++) {
        if(count === +props.currentSlide) {
            dots.push( <div key={ count } className={ cn(classes.dot, classes.active) } /> )
        } else {
            dots.push( <div key={ count } className={ classes.dot } /> )
        }
    }

    return (
        <div className={ classes.controls }>
            <div 
                className={ cn(classes.button, classes.prev, {
                    [classes.invisible]: props.currentSlide === 1
                }) } 
                onClick={ props.prevClicked }>
                Назад
            </div>
            <div className={ classes.dots }>
                { dots }
            </div>
            <div className={ cn(classes.button, classes.next) } onClick={ props.nextClicked }>
                { props.currentSlide === props.slidesNum ? 'Понятно' : 'Дальше' }
            </div>
        </div>
    )
}

export default Controls;