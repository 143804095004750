import React from 'react';
import classes from './GetReady.module.scss';

const GetReady = (props) => (
    <div className={ classes.wrapper }>
        <div className={ classes.eventName }>{ props.gameName }</div>
        <div className={ classes.circle }>
            <div className={ classes.inner }>
                <span/>
            </div>
        </div>
        <div className={ classes.text }>{ props.name }, расслабься! Вопросы будут появляться автоматически, просто успевай на них отвечать</div>
    </div>
)

export default GetReady;