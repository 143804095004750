import React from 'react';
import classes from './WinnerScreen.module.scss';
import Dots from '../Question/Dots/Dots';
import winnerIcon from '../../../assets/img/winner.svg';

const WinnerScreen = (props) => (
    <div className={ classes.winnerScreen }>
        <div className={ classes.eventName }>{ props.gameName }</div>
        <div className={ classes.greetings }>
            { props.name }, поздравляем, ты победил
        </div>
        <div className={ classes.results }>
            <span>#1</span>/{ props.totalPlayers }
        </div>
        <img src={ winnerIcon } className={ classes.winnerIcon } />
        <Dots
            currentstatus={ 'correct' }
            questionsCount={ props.questionsTotalNumber }
            currentQuestion={ props.questionNumber }
        />
    </div>
)

export default WinnerScreen;