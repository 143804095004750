import React from 'react';
import classes from './QrScreen.module.scss';

const QrScreen = (props) => (
    <div className={ classes.qrScreen }>
        <div className={ classes.usersCount }>
            { props.usersCount ? props.usersCount : 0 } <span>участников</span>
        </div>
        <div className={ classes.qrBackground }>
            <img src={ props.qrCode } alt="" className={ classes.qrCode } />
        </div>
        <div className={ classes.url }>
            { props.gameUrl }
        </div>
    </div>
)

export default QrScreen;