import React from 'react';
import classes from './Option.module.scss';
import cn from 'classnames';

const Option = (props) => {

    const getLetter = () => {
        switch(props.index) {
            case 0:
                return 'A:'
            case 1:
                return 'B:'
            case 2:
                return 'C:'
            case 3:
                return 'D:'
        }

    }

    return(
        <div className={ cn(classes.optionContainer, {
            [classes.disabled]: props.isDisabled
        }) }>
            <div className={ classes.inner } style={ { width: `${ props.percent }%` } } />
            <div 
                className={ cn(classes.option, {
                    [classes.pending]: props.pending,
                    [classes.correct]: props.correct,
                    [classes.wrong]: props.wrong
                }) }
                onClick={ () => props.onOptionClick(props.index)}
            >
                <div className={ classes.letter }>
                    { getLetter() }
                </div>
                <div className={ classes.text }>
                    {props.text}
                </div>
                { props.showPercent && <div className={ classes.percent }>{ props.votes }</div> }
            </div>                                          
        </div>
    )
}

export default Option;