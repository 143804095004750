import React from 'react';
import classes from './TimeoutScreen.module.scss';
import Dots from '../Question/Dots/Dots';
import timeoutIcon from '../../../assets/img/clock.svg';

const TimeoutScreen = (props) => (
    <div className={ classes.timeoutScreen }>
        <div className={ classes.eventName }>{ props.gameName }</div>
        <div className={ classes.greetings }>
            { props.name }, увы, ты не успел ответить
        </div>
        <div className={ classes.results }>
            <span>#{ props.rankingPlace ? props.rankingPlace : 2 }</span>/{ props.totalPlayers }
        </div>
        <img src={ timeoutIcon }/>
        <Dots
            currentstatus={ 'wrong' }
            questionsCount={ props.questionsTotalNumber }
            currentQuestion={ props.questionNumber }
        />
    </div>
)

export default TimeoutScreen;