import React from 'react';
import classes from './Dots.module.scss';
import cn from 'classnames';

const Dots = (props) => {

    let dots = []

    for (let count = 1; count <= props.questionsCount; count++) {
        if(count === +props.currentQuestion) {
            dots.push( <div key={ count } className={ cn(classes.dot, classes.active) } /> )
        } else if(count < +props.currentQuestion) {
            dots.push( <div key={ count } className={ cn(classes.dot, classes.past) } /> )
        } else {
            dots.push( <div key={ count } className={ classes.dot } /> )
        }
    }
    
    return (
        <div className={ classes.dots }>
            { dots }
        </div>
    )
}

export default Dots;