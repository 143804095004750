import React from 'react';
import classes from './AlertScreen.module.scss';
import alertIcon from '../../../assets/img/screen-alert.svg';

const AlertScreen = (props) => (
    <div className={ classes.alertScreen }>
        <div className={ classes.gameName }>{ props.name }</div>
        <div className={ classes.circle }>
            <div className={ classes.innerCircle }>
                <img src={ alertIcon } className={ classes.icon } />
            </div>
        </div>
        <div className={ classes.title }>Внимание! Финальный вопрос.</div>
        <div className={ classes.subtitle }>Победителем станет участник, который даст правильный ответ первым</div>
    </div>
)

export default AlertScreen;