import React from 'react';
import classes from './ProgressBar.module.scss';
import cn from 'classnames';

const ProgressBar = (props) => {
    return(
        <div className={ classes.progressBar }>
            <div
                className={ cn(classes.progressLine, {
                    [classes.active]: props.isTimerStarted
                }) } 
                style={ { animationDuration: `${ props.time }s` } }
            />
        </div>  
    )
}

export default ProgressBar;