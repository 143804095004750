import React, { Component } from 'react';
import classes from './Tutorial.module.scss';
import Controls from './Controls/Controls';
import questionIcon from '../../../assets/img/tutorial-questions.svg';
import answerIcon from '../../../assets/img/tutorial-answers.svg';
import clockIcon from '../../../assets/img/clock.svg';
import looserIcon from '../../../assets/img/looser.svg';
import winnerIcon from '../../../assets/img/tutorial-winner.svg';

class Tutorial extends Component {
    state = {
        slides: 5,
        currentSlide: 1
    }

    handleNextClicked = () => {
        if(this.state.currentSlide === this.state.slides) {
            this.props.getReadyClicked()
        } else if(this.state.currentSlide < this.state.slides) {
            const nextSlide = this.state.currentSlide + 1;
            this.setState({
                currentSlide: nextSlide
            })
        }
    }

    handlePrevClicked = () => {
        const prevSlide = this.state.currentSlide - 1;
        this.setState({
            currentSlide: prevSlide
        })
    }

    render() {

        let slideIcon = questionIcon
        let slideText = (
            <div className={ classes.text }>
                На твоем экране будет отображаться <span>вопрос</span> и <span>4 варианта ответа</span>
            </div>
        )

        switch(this.state.currentSlide){
            case 1:
                slideIcon = questionIcon;
                slideText = (
                    <div className={ classes.text }>
                        На твоем экране будет отображаться <span>вопрос</span> и <span>4 варианта ответа</span>
                    </div>
                )
                break;
            case 2:
                slideIcon = answerIcon
                slideText = (
                    <div className={ classes.text }>
                        Ответь <span>правильно</span>, чтобы пройти в <span>следующий тур</span>
                    </div>
                )
                break;
            case 3:
                slideIcon = clockIcon
                slideText = (
                    <div className={ classes.text }>
                        На ответ даётся <span>15 секунд</span>
                    </div>
                )
                break;
            case 4:
                slideIcon = looserIcon
                slideText = (
                    <div className={ classes.text }>
                        Игроки, которые ответят <span>неправильно – выбывают из игры</span>
                    </div>
                )
                break; 
            case 5:
                slideIcon = winnerIcon
                slideText = (
                    <div className={ classes.text }>
                        <span>Побеждает</span> тот, кто даст <span>наибольшее количество</span> правильных ответов
                    </div>
                )
                break; 
            default:
                slideIcon = null
        }

        return(
            <div className={ classes.tutorial }>
                <div className={ classes.eventName }>
                    {/* { this.props.gameName } */}
                    Battle Royale
                </div>
                <div className={ classes.circle }>
                    <div className={ classes.inner }>
                        <img src={ slideIcon } />
                    </div>
                </div>
                { slideText }
                <Controls
                    slidesNum={ this.state.slides }
                    currentSlide={ this.state.currentSlide }
                    nextClicked={ this.handleNextClicked }
                    prevClicked={ this.handlePrevClicked }
                /> 
            </div>
        )
    }
}

export default Tutorial;