import React from 'react';
import classes from './ProgressBar.module.scss';
import cn from 'classnames';

const ProgressBar = (props) => {

    let innerClasses = classes.inner
    if(props.isCountdownStarted) {
        innerClasses = cn(classes.inner, classes.active)
    }

    return (
        <div className={ classes.progressBar }>
            <div className={ innerClasses }  style={ { animationDuration: `${ props.time }s` } }></div>
        </div>
    )
}

export default ProgressBar;