import React, { Component } from 'react';
import classes from './Game.module.scss';
import SignUp from './SignUp/SignUp';
import GetReady from './GetReady/GetReady';
import Question from './Question/Question';
import WinnerScreen from './WinnerScreen/WinnerScreen';
import LooserScreen from './LooserScreen/LooserScreen';
import AlertScreen from './AlertScreen/AlertScreen';
import Tutorial from './Tutorial/Tutorial';
import TimeoutScreen from './TimeoutScreen/TimeoutScreen';
import WakeLock from 'react-wakelock-react16';

// const Socket = new WebSocket("ws://89.184.92.182:2346");

class Game extends Component {

    state = {
        userId: null,
        name: '',
        nameError: false,
        progress: 'signUp',
        questionsNum: null,
        timeout: null,
        gameName: null,
        currentQuestion: null,
        pendingAnswer: null,
        trueAnswer: null,
        falseAnswer: null,
        questionText: null,
        isOptionsVisible: false,
        options: null,
        usersToNextLevel: null,
        votes: null,
        questionStatus: 'new',
        rankingPlace: null,
        goAhead: true,
        showVotes: false,
        isOptionsDisabled: true,
        isTimerStarted: false
    }

    socket = new WebSocket("ws://europc.pynex.co:2346");
    
    componentDidMount() {

        this.socket.onopen = () => {
            console.log('opened')
            const userId = sessionStorage.getItem('userId');
            if(userId) {
                console.log('user id sent')
                this.socket.send(JSON.stringify({
                    user_id: userId,
                    type: 'reconnect'
                }));
                this.setState({
                    userId: userId
                })
            };
        };
        
        this.socket.onclose = (event) => {
            if (event.wasClean) {
            console.log('Соединение закрыто чисто');
            } else {
            console.log('Обрыв соединения'); // например, "убит" процесс сервера
            }
            console.log('Код: ' + event.code + ' причина: ' + event.reason);
        };
        
        this.socket.onmessage = (event) => {
            this.onResponse(event.data) 
        };
        
        this.socket.onerror = (error) => {
            console.log("error: " + error.message);
        };
    }

    onResponse(data) {
        let response = JSON.parse(data)
        console.log(response)
        switch(response.type){
            case 'userId':
                if(this.state.userId){
                    this.socket.send(JSON.stringify({
                        input: 'user',
                        type: 'game_info',
                        game_id: 1,
                        user_id: this.state.userId
                    }));
                } else {
                    this.socket.send(JSON.stringify({
                        input: 'user',
                        type: 'game_info',
                        game_id: 1,
                        user_id: response.id
                    }));
                    sessionStorage.setItem('userId', response.id)
                    this.setState({
                        userId : response.id
                    })
                }
            case 'game_info':
                this.setState({
                    questionsNum: response.questions_count,
                    timeout: response.timer_sec,
                    gameName: response.game_name 
                })
                break;
            case 'regSuccess':
                this.setState({
                    progress: 'tutorial'
                })    
                break;
            case 'question':
                if(this.state.goAhead) {
                    this.setState({
                        currentQuestion: response.num,
                        questionText: response.question,
                        progress: 'question',
                        isOptionsVisible: false,
                        isOptionsDisabled: true,
                        questionStatus: 'new',
                        trueAnswer: null,
                        falseAnswer: null,
                        pendingAnswer: null,
                        showVotes: false,
                        isTimerStarted: false
                    })
                } else {
                    this.setState({
                        progress: 'looser'
                    })
                }
                break;
            case 'show_options':
                this.setState({
                    isOptionsVisible: true,
                    isOptionsDisabled: true,
                    options: response.options
                })
                break;
            case 'start_timer':
                    this.setState({
                        isTimerStarted: true,
                        isOptionsDisabled: false
                    })
                    break;
            case 'true_answer':
                if(response.status && +response.true_option === this.state.pendingAnswer){
                    this.setState({
                        trueAnswer: response.true_option,
                        pendingAnswer: null,
                        falseAnswer: null,
                        usersToNextLevel: response.players,
                        votes: response.votes,
                        questionStatus: 'correct',
                        showVotes: true
                    })       
                } else if(response.status) {
                    this.setState({
                        trueAnswer: response.true_option,
                        falseAnswer: this.state.pendingAnswer,
                        pendingAnswer: null,
                        usersToNextLevel: response.players,
                        votes: response.votes,
                        questionStatus: 'allWrong',
                        showVotes: true
                    })       
                } else if(!response.status && this.state.pendingAnswer === null){
                    this.setState({
                        trueAnswer: response.true_option,
                        falseAnswer: this.state.pendingAnswer,
                        usersToNextLevel: response.players,
                        rankingPlace: response.rate_place,
                        totalPlayers: response.totalPlayers,
                        goAhead: false,
                        votes: response.votes,
                        questionStatus: 'wrong',
                        showVotes: true,
                        progress: 'timeout'
                    })  
                } else {
                    this.setState({
                        trueAnswer: response.true_option,
                        falseAnswer: this.state.pendingAnswer,
                        pendingAnswer: null,
                        usersToNextLevel: response.players,
                        rankingPlace: response.rate_place,
                        totalPlayers: response.totalPlayers,
                        goAhead: false,
                        votes: response.votes,
                        questionStatus: 'wrong',
                        showVotes: true
                    })       
                }
                break;
            case 'winner':
                if(response.status){
                    this.setState({
                        progress: 'winner',
                        totalPlayers: response.totalPlayers
                    })
                } else {
                    this.setState({
                        progress: 'looser',
                        totalPlayers: response.totalPlayers
                    })
                }
                break;
            case 'show_alert':
                this.setState({
                    progress: 'alert'
                })
                break;
            default:
                console.log('unknown message')
                break;
        }
    }

    sendUsername = () => {
        if(this.state.name === ''){
            this.setState({nameError: true})
        } else {
            this.socket.send(JSON.stringify({
                input: 'user',
                user_id: this.state.userId,
                type: 'username',
                name: this.state.name
            }));
        }
    }

    sendAnswer = (selectedOption) => {
        console.log('answer sent')
        this.socket.send(JSON.stringify({
            input: 'user',
            game_id: 1,
            user_id: this.state.userId,
            type: 'answer',
            answer: selectedOption
        }));
        this.setState({
            pendingAnswer: +selectedOption
        })
    }

    changeName = (event) => {
        this.setState({
            name: event.target.value
        })
    }

    getReadyHandler = () => {
        this.setState({
            progress: 'getReady'
        })
    }

    render() {

        let content = null
        if(this.state.progress === 'signUp'){
            content = (
                <SignUp 
                    readyClicked={ this.sendUsername } 
                    gameName={ this.state.gameName }
                    name={ this.state.name } 
                    changed={ (event) => this.changeName(event) }
                    error={ this.state.nameError }
                />
            )
        } else if(this.state.progress === 'getReady') {
            content = (
                <GetReady 
                    gameName={ this.state.gameName }
                    name={ this.state.name } 
                />
            )
        } else if(this.state.progress === 'question') {
            content = (
                <Question
                    question={ this.state.questionText }
                    questionNumber={ this.state.currentQuestion }
                    questionsTotalNumber={ this.state.questionsNum }
                    time={ this.state.timeout }
                    isOptionsVisible={ this.state.isOptionsVisible }
                    isOptionsDisabled={ this.state.isOptionsDisabled }
                    options={ this.state.options }
                    pendingAnswer={ this.state.pendingAnswer }
                    trueAnswer={ this.state.trueAnswer }
                    falseAnswer={ this.state.falseAnswer }
                    questionStatus={ this.state.questionStatus }
                    onOptionClick={ (selectedOption) => this.sendAnswer(selectedOption) }
                    usersToNextLevel={ this.state.usersToNextLevel }
                    showVotes={ this.state.showVotes }
                    votes={ this.state.votes }
                    isTimerStarted={ this.state.isTimerStarted }
                />
            )
        } else if(this.state.progress === 'winner') {
            content = (
                <WinnerScreen 
                    totalPlayers={ this.state.totalPlayers }
                    gameName={ this.state.gameName }
                    name={ this.state.name } 
                    questionNumber={ this.state.currentQuestion }
                    questionsTotalNumber={ this.state.questionsNum }
                />
            )
        } else if(this.state.progress === 'looser'){
            content = (
                <LooserScreen
                    rankingPlace={ this.state.rankingPlace }
                    totalPlayers={ this.state.totalPlayers }
                    gameName={ this.state.gameName }
                    name={ this.state.name } 
                    questionNumber={ this.state.currentQuestion }
                    questionsTotalNumber={ this.state.questionsNum }
                />
            )
        } else if(this.state.progress === 'alert') {
            content = (
                <AlertScreen
                    gameName={ this.state.gameName }
                />
            )
        } else if(this.state.progress === 'tutorial') {
            content = (
                <Tutorial 
                    gameName={ this.state.gameName }
                    getReadyClicked={ this.getReadyHandler }
                />
            )
        } else if(this.state.progress === 'timeout') {
            content = (
                <TimeoutScreen
                    rankingPlace={ this.state.rankingPlace }
                    totalPlayers={ this.state.totalPlayers }
                    gameName={ this.state.gameName }
                    name={ this.state.name } 
                    questionNumber={ this.state.currentQuestion }
                    questionsTotalNumber={ this.state.questionsNum }
                />
            )
        }
        return(
            <div className={ classes.container }>
                <WakeLock />
                {content}
            </div>
        )
    }
}

export default Game;