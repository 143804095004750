import * as actionTypes from '../actions/actionTypes';

const initialState = {
    lang: 'ua',
    type: 'kyiv'
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.SWITCH_LANG:
            return {
                lang: action.lang
            }
        case actionTypes.TYPE_SWITCH:
            return {
                type: action.selectedType
            }
        default:
            return state;
    }

}

export default reducer;