import React from 'react';
import classes from './WinnerScreen.module.scss';
import crownIcon from '../../../assets/img/screen-crown.png'

const WinnerScreen = (props) => (
    <div className={ classes.winnerScreen }>
        <div className={ classes.gameName }>{ props.name }</div>
        <img className={ classes.icon } alt="" src={ crownIcon } />
        <div className={ classes.title }>{ props.winnerName }, поздравляем с победой!</div>
        <div className={ classes.stats }>
            <span>#1</span> / { props.totalUsersCount }
        </div>
    </div>  
)

export default WinnerScreen;