import React, { Component } from 'react';
import classes from './Controller.module.scss';
import cn from 'classnames';

class Controller extends Component{

    state = {
        userId: null,
        progress: 'firstScreen',
        questionCount: null,
        timeout: null,
        disableButton: false,
        currentQuestion: 0
    }

    socket = new WebSocket("ws://europc.pynex.co:2346");

    componentDidMount() {

        this.socket.onopen = () => {
            console.log('opened')
            const userId = sessionStorage.getItem('userId');
            if(userId) {
                console.log('user id sent')
                this.socket.send(JSON.stringify({
                    user_id: userId,
                    type: 'reconnect'
                }));
                this.setState({
                    userId: userId
                })
            }
        };
        
        this.socket.onclose = (event) => {
            if (event.wasClean) {
            console.log('Соединение закрыто чисто');
            } else {
            console.log('Обрыв соединения'); // например, "убит" процесс сервера
            }
            console.log('Код: ' + event.code + ' причина: ' + event.reason);
        };
        
        this.socket.onmessage = (event) => {
            this.onResponse(event.data) 
        };
        
        this.socket.onerror = (error) => {
            console.log("error: " + error.message);
        };
    }

    componentDidUpdate(){
        if(this.state.disableButton){
            setTimeout(() => {
                this.setState({
                    disableButton: false
                })
            }, this.state.timeout * 1000)
        }
    }

    onResponse(data) {
        let response = JSON.parse(data)
        console.log(response)
        switch(response.type){
            case 'userId':
                if(this.state.userId){
                    this.socket.send(JSON.stringify({
                        input: 'admin',
                        type: 'game_info',
                        password: '0111001110000110110rap',
                        game_id: 1,
                        user_id: this.state.userId
                    }));
                } else {
                    this.socket.send(JSON.stringify({
                        input: 'admin',
                        type: 'game_info',
                        password: '0111001110000110110rap',
                        game_id: 1,
                        user_id: response.id
                    }));
                    sessionStorage.setItem('userId', response.id)
                    this.setState({
                        userId : response.id
                    })
                }
                break;
            case 'game_info':
                this.setState({
                    questionCount: response.questions_count,
                    timeout: response.timer_sec
                })
                break;
            default:
                console.log('unknown message')
                break;
        }
    }

    buttonPress = () => {
        switch(this.state.progress){
            case 'firstScreen':
                this.socket.send(JSON.stringify({
                    input: 'admin',
                    password: '0111001110000110110rap',
                    type: 'start_qr',
                    game_id: 1
                }));
                this.setState({
                    progress: 'qrScreen'
                })
                break;
            case 'qrScreen':
                this.socket.send(JSON.stringify({
                    input: 'admin',
                    password: '0111001110000110110rap',
                    type: 'to_tutorial',
                    game_id: 1
                }));
                this.setState({
                    progress: 'tutorial'
                })
                break;
            case 'tutorial':
                this.socket.send(JSON.stringify({
                    input: 'admin',
                    password: '0111001110000110110rap',
                    type: 'start_game',
                    game_id: 1
                }));
                this.setState({
                    progress: 'question',
                    currentQuestion: 1
                })
                break;
            case 'question':
                this.socket.send(JSON.stringify({
                    input: 'admin',
                    password: '0111001110000110110rap',
                    type: 'show_options',
                    game_id: 1
                }));
                this.setState({
                    progress: 'options'
                })
                break;
            case 'options':
                this.socket.send(JSON.stringify({
                    input: 'admin',
                    password: '0111001110000110110rap',
                    type: 'start_timer',
                    game_id: 1
                }));
                this.setState({
                    progress: 'timer',
                    disableButton: true
                })
                break;
            case 'timer':
                this.socket.send(JSON.stringify({
                    input: 'admin',
                    password: '0111001110000110110rap',
                    type: 'true_answer',
                    game_id: 1
                }));
                this.setState({
                    progress: 'answer'
                })
                break;
            case 'answer':
                if(this.state.currentQuestion === (this.state.questionCount - 1)){
                    this.socket.send(JSON.stringify({
                        input: 'admin',
                        password: '0111001110000110110rap',
                        type: 'show_alert',
                        game_id: 1
                    }));
                    this.setState({
                        progress: 'alert',
                    })
                } else {
                    this.socket.send(JSON.stringify({
                        input: 'admin',
                        password: '0111001110000110110rap',
                        type: 'next',
                        game_id: 1
                    }));
                    this.setState({
                        progress: 'question',
                        currentQuestion: this.state.currentQuestion + 1
                    })
                }
                break;
            case 'alert':
                this.socket.send(JSON.stringify({
                    input: 'admin',
                    password: '0111001110000110110rap',
                    type: 'next',
                    game_id: 1
                }));
                this.setState({
                    progress: 'question'
                })
                break;
            default:
                break;
        }
    }

    stopGame = () => {
        this.socket.send(JSON.stringify({
            input: 'admin',
            password: '0111001110000110110rap',
            type: 'stop_game',
            game_id: 1
        }));
        this.setState({
            progress: 'firstScreen'
        })
    }

    render(){
        let buttonText
        switch(this.state.progress) {
            case 'firstScreen':
                buttonText = 'Показать QR';
                break;
            case 'qrScreen':
                buttonText = 'Показать туториал';
                break;
            case 'tutorial': 
                buttonText = 'Начать игру';
                break;
            case 'question':
                buttonText = 'Показать варианты';
                break;
            case 'options':
                buttonText = 'Запустить таймер';
                break;
            case 'timer':
                buttonText = 'Показать ответ';
                break;
            case 'answer':
                if(this.state.currentQuestion === (this.state.questionCount - 1)){
                    buttonText = 'Показать предупреждение'
                } else {
                    buttonText = 'Следующий вопрос';
                }
                break;
            case 'alert':
                buttonText = 'Следующий вопрос';
                break;
        }


        return(
            <div className={ classes.controller }>
                <div className={ classes.title }>
                    Ты<div>управляешь</div>вселенной!
                </div>
                <div className={ cn(classes.button, {
                    [classes.disabled]: this.state.disableButton
                }) } onClick={ this.buttonPress }>
                    { buttonText }
                </div>
                <div className={ classes.offButton } onClick={ this.stopGame }>Остановть Игру</div>
            </div>
        )
    }
}

export default Controller;