import React from 'react';
import classes from './QuestionScreen.module.scss';
import Dots from './Dots/Dots';
import Option from './Option/Option';
import ProgressBar from './Timer/ProgressBar';
import Timer from './Timer/Timer';
import Results from './Results/Results';

const QuestionScreen = (props) => {

    let options;

    if(props.isResultsShown) {
        let totalVotes = 0
        props.votes.forEach(item => {
            totalVotes = totalVotes + item
        });

        let percents = props.votes.map(item => item * 100 / totalVotes)
        let optionsWithVotes = props.options.map((option, index) => {
            return {
                option: option,
                percent: percents[index],
                votes: props.votes[index]
            }
        })
        options = optionsWithVotes.map((option, index) => {
            if(props.correctAnswer !== null && props.correctAnswer === index) {
                return (
                    <Option 
                        correct 
                        showPercent
                        percent={ option.percent }
                        votes={ option.votes }
                        index={ index }
                        text={ option.option }
                        key={index} 
                    />
                )
            } else {
                return (
                    <Option 
                        showPercent
                        percent={ option.percent }
                        votes={ option.votes }
                        index={ index }
                        text={ option.option }
                        key={index} 
                    />
                )
            }
        })
    } else if(props.isOptionsVisible) {
        options = props.options.map((option, index) => {
            if(props.correctAnswer !== null && props.correctAnswer === index) {
                return (
                    <Option 
                        correct 
                        index={ index } 
                        text={ option } 
                        key={index}
                    />
                )
            } else {
                return (
                    <Option 
                        index={ index } 
                        text={ option } 
                        key={index} 
                    />
                )
            }
        })
    }

    return (
        <div className={ classes.questionScreen }>
            <div className={ classes.cusestionsCount }>
                <span>Вопрос</span> {  props.currentQuestion } <span>из</span> { props.questionsCount } <span>|</span> { props.playersCount } <span>игроков</span>
            </div>
            <div className={ classes.questionText }>
                { props.questionText }
            </div>
            { props.isOptionsVisible && (
                <div className={ classes.options }>
                    { props.isResultsShown ?  <Results playersCount={ props.playersCount } /> :
                    <Timer timeLeft={ props.timerTime } isCountdownStarted={ props.isCountdownStarted }/>  }
                    <ProgressBar time={ props.timerTime } isCountdownStarted={ props.isCountdownStarted } />
                    <div className={ classes.optionsWrapper }>
                        { options }
                    </div>
                </div>
            ) }
            <Dots
                questionsCount={ props.questionsCount }
                currentQuestion={ props.currentQuestion }
            />
        </div>
    )
}

export default QuestionScreen;