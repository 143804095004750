import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classes from './App.module.scss';
import Home from './components/Home/Home';
import Game from './components/Game/Game';
import Controller from './components/Сontroller/Controller';
import Screen from './components/Screen/Screen';

import { connect } from 'react-redux';
import * as actions from './store/actions';

class App extends Component {
  render(){
    const currentKey = window.location.pathname
    const timeout = { enter: 500, exit: 300 }

    
    return (
      <div className={ classes.app }>
        <TransitionGroup component="main" className="page-main" appear>
          <CSSTransition key={ currentKey } timeout={ timeout } classNames="fade">
            <Switch location={window.location}>
              <Route path="/" exact component={ Home } />
              <Route path="/game" exact component={ Game } />
              <Route path="/controller" exact component={ Controller } />
              <Route path="/screen" exact component={ Screen } />
            </Switch>
          </CSSTransition>  
        </TransitionGroup>
      </div>
    );
  }
}

export default App;
