import React, { Component } from 'react';
import classes from './Timer.module.scss'

class Timer extends Component {

    state = {
        currentCount: this.props.timeLeft
    }

    componentDidMount() {
        if(this.props.isCountdownStarted) {
            let intervalId = setInterval(this.timer, 1000);
            this.setState({ intervalId: intervalId });
        }
    }

    componentDidUpdate() {
        if(this.props.isCountdownStarted && !this.state.intervalId) {
            let intervalId = setInterval(this.timer, 1000);
            this.setState({ intervalId: intervalId });
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
     }
     
    timer = () => {
        let newCount = this.state.currentCount - 1;
        if(newCount >= 0) { 
            this.setState({ currentCount: newCount });
        } else {
            clearInterval(this.state.intervalId);
        }
     }

    render() {

        let label = 'секунд';
        // let lastChar = +(this.state.currentCount % 10)
        
        // switch (lastChar){
        //     case 1:
        //         label = 'секунда';
        //         break;
        //     case 2:
        //     case 3:
        //     case 4:
        //         label = 'секунды';
        //         break;
        //     case 5:
        //     case 6:
        //     case 7:
        //     case 8:
        //     case 9:
        //     case 0:
        //         label = 'секунд';
        //         break;
        //     default:
        //         label = 'секунд';                 
        // }

        return (
            <div className={ classes.timer }>
                { this.state.currentCount } { label }
            </div>
        )
    }
}

export default Timer;