import React from 'react';
import classes from './TutorialScreen.module.scss';

const TutorialScreen = (props) => (
    <div className={ classes.tutorialScreen }>
        <div className={ classes.usersCount }>
            { props.usersCount ? props.usersCount : 0 } <span>участников</span>
        </div>
        <div className={ classes.title }>Правила игры:</div>
        <div className={ classes.wrapper }>
            <div className={ classes.tutorialContainer }>
                <ol>
                    <li><span>На экране смартфона будет отображаться <span>вопрос</span> и <span>4 варианта ответа</span></span></li>
                    <li><span>Необходимо отвечать <span>правильно</span>, чтобы пройти в <span>следующий тур</span></span></li>
                    <li><span>На ответ даётся <span>{ props.time } секунд</span></span></li>
                    <li><span>Игроки, которые ответят <span>неправильно – выбывают из игры</span></span></li>
                    <li><span><span>Побеждает</span> тот, кто даст <span>наибольшее количество</span> правильных ответов</span></li>
                </ol>
            </div>
            <div className={ classes.codeContainer }>
                <img src={ props.qrCode } alt="" className={ classes.qrCode } />
            </div>
        </div>
    </div>
)

export default TutorialScreen;