import React from 'react';
import classes from './Option.module.scss';
import cn from 'classnames';

const Option = (props) => {

    const getLetter = () => {
        switch(props.index) {
            case 0:
                return 'A:'
            case 1:
                return 'B:'
            case 2:
                return 'C:'
            case 3:
                return 'D:'
        }
    }

    return(
        <div className={ classes.optionContainer }>
            <div className={ classes.inner } style={ { width: `${ props.percent }%` } } />
            <div 
                className={ cn(classes.option, {
                    [classes.correct]: props.correct,
                }) }
            >
            <div className={ classes.inner } />
            <div className={ classes.letter }>
                { getLetter() }
            </div>
            <div className={ classes.text }>
                {props.text}
            </div>
                { props.showPercent && <div className={ classes.percent }>{ props.votes }</div> }
            </div>
        </div>
    )
}

export default Option;