import React from 'react';
import classes from './BottomButton.module.scss';
import { Link } from 'react-router-dom';

const BottomButton = (props) => {

    let button

    if( props.link ){
        button = (
            <Link to={ props.url } className={ classes.button }>
                { props.children }
            </Link>
        )
    } else {
        button = (
            <div className={ classes.button } onClick={ props.clicked }>
                { props.children }
            </div>
        )
    }

    return button
    
}

export default BottomButton;