import React, { Component } from 'react';
import classes from './Home.module.scss';
import crownIcon from '../../assets/img/crown.png';
import BottomButton from '../BottomButton/BottomButton';

class Home extends Component {
    render() {
        return (
            <div className={ classes.container }>
                <div className={ classes.eventName }>Just Hype Party</div>
                <img src={ crownIcon } className={ classes.icon } onClick={this.sendAnswer}/>
                <div className={ classes.title } onClick={ this.sendUsername }>Королевская битва</div>
                <div className={ classes.subtitle } onClick={ this.startGame }>Интеллектуальный баттл</div>
                <BottomButton link url={ 'game' }>Погнали!</BottomButton>
            </div>
        )
    }
}

export default Home;