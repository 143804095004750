import React from 'react';
import classes from './Question.module.scss';
import ProgressBar from './ProgressBar/ProgressBar';
import Option from './Option/Option';
import Dots from './Dots/Dots';
import cn from 'classnames';

const Question = (props) => {

    let options = null

    if(props.showVotes) {
        let totalVotes = 0
        props.votes.forEach(item => {
            totalVotes = totalVotes + item
        });

        let percents = props.votes.map(item => item * 100 / totalVotes)
        let optionsWithVotes = props.options.map((option, index) => {
            return {
                option: option,
                percent: percents[index],
                votes: props.votes[index]
            }
        })
        options = optionsWithVotes.map((option, index) => {
            if(props.trueAnswer !== null && props.trueAnswer === index) {
                return(
                    <Option
                        correct
                        showPercent
                        percent={ option.percent }
                        votes={ option.votes }
                        index={ index }
                        text={ option.option }
                        key={ index }
                        onOptionClick={ (selectedOption) => props.onOptionClick(selectedOption)}
                    />
                )
            } else if(props.falseAnswer !== null && props.falseAnswer === index) {
                return(
                    <Option
                        wrong
                        showPercent
                        percent={ option.percent }
                        votes={ option.votes }
                        index={ index }
                        text={ option.option }
                        key={ index }
                        onOptionClick={ (selectedOption) => props.onOptionClick(selectedOption)}
                    />
                )
            } else {
                return(
                    <Option
                        showPercent
                        percent={ option.percent }
                        votes={ option.votes }
                        index={ index }
                        text={ option.option }
                        key={ index }
                        onOptionClick={ (selectedOption) => props.onOptionClick(selectedOption)}
                    />
                )
            }
        })
    } else if(props.isOptionsVisible) {
        options = props.options.map((option, index) => {
            if(props.pendingAnswer !== null && props.pendingAnswer === index) {
                return(
                    <Option
                        pending
                        index={ index }
                        text={ option }
                        key={ index }
                        isDisabled={ props.isOptionsDisabled }
                        onOptionClick={ (selectedOption) => props.onOptionClick(selectedOption)}
                    />
                )
            } else if(props.trueAnswer !== null && props.trueAnswer === index) {
                return(
                    <Option
                        correct
                        index={ index }
                        text={ option }
                        key={ index }
                        isDisabled={ props.isOptionsDisabled }
                        onOptionClick={ (selectedOption) => props.onOptionClick(selectedOption)}
                    />
                )
            } else if(props.falseAnswer !== null && props.falseAnswer === index) {
                return(
                    <Option
                        wrong
                        index={ index }
                        text={ option }
                        key={ index }
                        isDisabled={ props.isOptionsDisabled }
                        onOptionClick={ (selectedOption) => props.onOptionClick(selectedOption)}
                    />
                )
            } else {
                return(
                    <Option
                        index={ index }
                        text={ option }
                        key={ index }
                        isDisabled={ props.isOptionsDisabled }
                        onOptionClick={ (selectedOption) => props.onOptionClick(selectedOption)}
                    />
                )
            }
        })
    }

    let question
    if(props.questionStatus === 'new') {
        question = <div className={ classes.questionText }>{ props.question }</div>
    } else if(props.questionStatus === 'correct') {
        question = (
            <div className={ classes.resultContainer }>
                <div className={ classes.correctTitle }>Красава!</div>
                <div className={ classes.desciption }>
                    В следующий тур проходишь ты и еще <span>{ props.usersToNextLevel - 1 }</span> участников.
                </div>
            </div>
        )
    } else if(props.questionStatus === 'wrong') {
        question = (
            <div className={ classes.resultContainer }>
                <div className={ classes.wrongTitle }>Сорян!</div>
                <div className={ classes.desciption }>
                    Ты держался достойно!<br/>
                    В следующий тур проходят <span>{ props.usersToNextLevel }</span> участников.
                </div>
            </div>
        )
    } else if(props.questionStatus === 'allWrong') {
        question = (
            <div className={ classes.resultContainer }>
                <div className={ classes.correctTitle }>Повезло!</div>
                <div className={ classes.desciption }>
                    Никто из <span>{ props.usersToNextLevel }</span> участников не ответил правильно и все переходят в следующий тур.
                </div>
            </div>
        )
    }

    return (
        <div className={ cn(classes.wrapper, {
            [classes.centered]: !props.isOptionsVisible
        }) }>
            <div className={ classes.questionsCount }>
                { props.questionNumber } вопрос из { props.questionsTotalNumber }
            </div>
            { question }
            { props.isOptionsVisible && (
                <div className={ classes.answersWrapper }>
                    <ProgressBar time={props.time} isTimerStarted={ props.isTimerStarted }/>
                    { options }
                </div>
            ) }
            <Dots 
                currentstatus={ props.questionStatus }
                questionsCount={ props.questionsTotalNumber }
                currentQuestion={ props.questionNumber }
            />
        </div>
    )
}

export default Question;