import React from 'react';
import classes from './AlertScreen.module.scss';
import alertIcon from '../../../assets/img/alert.svg'

const AlertScreen = (props) => (
    <div className={ classes.wrapper }>
        <div className={ classes.eventName }>{ props.gameName }</div>
        <div className={ classes.circle }>
            <div className={ classes.inner }>
                <img src={ alertIcon } />
            </div>
        </div>
        <div className={ classes.text }>
            <div>Внимание! Финальный вопрос.</div> 
            Победителем станет участник, который даст правильный ответ первым
        </div>
    </div>
)

export default AlertScreen;